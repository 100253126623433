<template>
  <div
    ref="next-topic"
    :class="{ 'next-topic': true, 'next-view-class': nextViewClass }"
  >
    <div class="next-middle">
      <div class="next-inner" @click="closeDetail">
        <p class="next-title">
          <span class="relative-topic">相关话题：</span>
          #{{ topicInfo.name }}#
        </p>
        <p class="next-introduction">
          <span>导语：</span>
          {{ topicInfo.description }}
        </p>

        <div class="content-tombstones" id="content-tombstones">
          <div class="desc"></div>
          <div class="paragraph">
            <p></p>
          </div>
          <div class="image"></div>
          <!-- <div class="paragraph">
            <p></p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { removeClass } from '@/util/index';
import { mapState } from 'vuex';
import VueCookies from 'vue-cookies';
export default {
  data() {
    return {
      nextViewClass: false,
    };
  },
  mounted() {
    this.initTop();
  },
  computed: {
    ...mapState(['currentTopicId', 'isViewDetail', 'topicInfo']),
  },
  methods: {
    // 初始化top 废弃
    initTop() {
      this.$nextTick(function() {
        setTimeout(() => {
          // const mainconent = document.getElementById('main-content');
          // const talkdetails = document.getElementById('talk-details');
          // const talktopic = document.getElementById('talk-topic');
          // const A = talkdetails.clientHeight + talktopic.clientHeight + 66;
          // const B = mainconent.clientHeight + getOffset(mainconent).top;
          // const cumulateTop = A - B;
          // this.$refs['next-topic'].style.top = `${cumulateTop}px`;
          this.nextViewClass = true;
        }, 800);
      });
    },
    // 跳转话题页面
    closeDetail() {
      if (!this.isViewDetail) return;
      const cnt = document.getElementById('talk-details');
      if (cnt) cnt.style.transition = 'none';
      this.$store.commit('setViewDetail', false);
      removeClass(
        document.getElementById('talk-topic-cos'),
        'talk-topic-fixed'
      );
      VueCookies.set('TJ_DATA', `detail|next|0|0`);
      this.$router.push({ path: `/talk/${this.currentTopicId}/` });
      document.body.style.overflow = 'Visible';
    },
  },
};
</script>

<style lang="scss">
.next-topic {
  float: right;
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 35px 0 25px 0;
  display: none;
  *top: 70px;
  top: 70px\0;
  &.next-view-class {
    display: block;
  }
  .next-middle {
    float: right;
    width: 1480px;
    color: #212121;
    background-color: #fff;
    .next-inner {
      width: 940px;
      cursor: pointer;
    }
    .next-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      padding-left: 170px;
      position: relative;
      text-align: left;
      .relative-topic {
        position: absolute;
        left: 0;
        width: 170px;
        text-align: center;
      }
    }
    .next-introduction {
      font-size: 14px;
      line-height: 24px;
      padding-left: 170px;
      padding-top: 8px;
      text-align: left;
      span {
        font-weight: bold;
      }
    }
  }
}
.content-tombstones {
  // display: none;
  height: 0;
  margin-top: 20px;
  overflow: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  // transition: height 0.5s ease 0s;
  .desc {
    width: 300px;
    height: 12px;
    background-color: #eee;
    border-radius: 0 10px 10px 0;
    margin-bottom: 10px;
  }
  .paragraph {
    p {
      height: 10px;
      width: 100%;
      margin-bottom: 10px;
      background-color: #e8e8e8;
      border-radius: 0 8px 8px 0;
    }
    &:last-child {
      width: 60%;
    }
  }
  .image {
    width: 90%;
    height: 65px;
    border-radius: 10px;
    background-color: #e8e8e8;
    margin: 10px auto;
  }
}
</style>
