<template>
  <div class="loading ball-pulse" id="loading-pulse1" v-if="loading">
    <div></div>
    <div></div>
    <div></div>
    <span>加载中⋅⋅⋅</span>
  </div>
</template>

<script>
export default {
  name: 'loading',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 16px;
  line-height: 16px;
  margin-top: 16px;
  margin-bottom: 4px;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #bdbdbd;
  &.ball-pulse > div {
    background-color: #bdbdbd;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin: 1px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
  }
  &.ball-pulse > div:nth-child(1) {
    -webkit-animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  &.ball-pulse > div:nth-child(2) {
    -webkit-animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  &.ball-pulse > div:nth-child(3) {
    -webkit-animation: scale 0.75s 0s infinite
      cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }

  &.ball-pulse > div {
    background-color: #bdbdbd;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin: 1px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
  }
  &.ball-pulse span {
    margin-left: 8px;
  }
}
</style>
