<template>
  <div
    class="all-scroll"
    ref="all-scroll"
    id="all-scroll"
    v-wechat-title="this.titleMeta"
  >
    <div class="scroll-inner" ref="scroll-inner" id="scroll-inner">
      <!-- 当前话题详情 -->
      <div
        class="talk-topic"
        :class="animateClass"
        id="talk-topic-article"
        @click="closeTalk('up')"
        @mouseover="topicMouseOver"
        @mouseleave="topicMouseLeave"
        style="opacity:0 !important"
      >
        <div class="talk-stage">
          <p class="title">-0-{{ topicInfo.name }}</p>
          <p class="focus-talk">
            <span class="num">{{ commentNum }}</span>
          </p>
          <p class="discription">
            <span>导语：</span>
            {{ topicInfo.description }}
          </p>
        </div>
      </div>
      <!-- 返回首页按钮 -->
      <div class="floatHome">
        <!-- <span class="back-home" @click="backHome"></span> -->
        <span class="scroll-top" @click="backTop"></span>
      </div>
      <div class="detail-close" ref="close">
        <span class="close" @click="closeTalk('close')"></span>
        <span class="guide" :class="isNewUser ? 'view' : 'hide'"></span>
      </div>
      <!-- 文章内容 -->
      <div class="detail-content" ref="detail-content">
        <div
          class="talk-details"
          :class="{
            'scroll-top': scrollTopClass,
            'change-zindex': isHoverTopic,
          }"
          ref="talk-details"
          id="talk-details"
        >
          <div class="countnew" v-if="data.topic">
            <h3>{{ data.topic }}</h3>
            <p class="form">
              <span class="source">{{ data.source }}</span>
              <span class="date">{{ data.date }}</span>
            </p>
            <div class="content" v-html="data.content"></div>
            <div class="record">
              tantao.com All Right Reserve 版权有所 沪ICP备14038113号-30
            </div>
          </div>
          <div class="loading"><loadIcon v-if="loading" /></div>
          <div class="count404" v-if="is404">
            <p class="tips-404">
              <span>哎呀，出错了！没有找到您需要的页面......</span>
            </p>
            <p class="icon-404">404</p>
            <p class="tips-text">您可以通过一下方式继续访问</p>
            <p class="back">
              <span class="btn" @click="closeTalk('back')">回到话题</span>
            </p>
          </div>
        </div>
        <!-- 向下指示标 -->
        <div class="next-icon" v-if="!is404">
          <span class="tips-icon"></span>
        </div>
      </div>
    </div>
    <!-- 下一篇新闻 -->
    <div class="relate" ref="relate" id="relate" v-show="nextView">
      <RelateTopic />
    </div>
    <!-- 新闻话题栏目 -->
    <div
      class="news-topic"
      v-if="viewHotTopic"
      :class="{ isUp: isUp }"
      v-show="recommendNewsData.length"
    >
      <h3 class="news-switch" @click="pcikDownRecTopic">
        话题推荐
        <span class="el-icon-arrow-right pack-down"></span>
      </h3>
      <div class="bounce-cnt">
        <ol class="newslist">
          <li v-if="recndata_line1.length">
            <div
              class="news-item"
              v-for="(item, idx) in recndata_line1"
              :key="idx"
              @click="goTopicDetail(item, idx)"
            >
              <span class="ms-lf">{{ item.section_name }}</span>
              <span
                class="ms-rg"
                :class="`lw${8 - String(item.section_name).length}`"
                >{{ item.title }}</span
              >
            </div>
          </li>
          <li v-if="recndata_line2.length">
            <div
              class="news-item"
              v-for="(item, idx) in recndata_line2"
              :key="idx"
              @click="goTopicDetail(item, idx + 2)"
            >
              <span class="ms-lf">{{ item.section_name }}</span>
              <span
                class="ms-rg"
                :class="`lw${8 - String(item.section_name).length}`"
                >{{ item.title }}</span
              >
            </div>
          </li>
          <li v-if="recndata_line3.length">
            <div
              class="news-item"
              v-for="(item, idx) in recndata_line3"
              :key="idx"
              @click="goTopicDetail(item, idx + 6)"
            >
              <span class="ms-lf">{{ item.section_name }}</span>
              <span
                class="ms-rg"
                :class="`lw${8 - String(item.section_name).length}`"
                >{{ item.title }}</span
              >
            </div>
          </li>
        </ol>
        <span
          class="pack-up el-icon-arrow-left"
          @click="packUpRecTopic"
          v-show="isUp"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsData, getRecommendNewsData } from '@/api/detail';
import {
  getScrollPosition,
  // scrollTo,
  getWindowHeight,
  scrollToDistance,
  isIEversion,
  supportWebp,
  addClass,
  removeClass,
} from '@/util/index';
import { mapState } from 'vuex';
import loadIcon from '@/components/loadIcon/index.vue';
import RelateTopic from '@/views/talk/relate/relate.vue';
import VueCookies from 'vue-cookies';
import { activeLog, err404Log, baiduLog } from '@/util/log.js';
let FormlY = '';
let isNeedWebp = supportWebp();
export default {
  name: 'Talkdetails',
  data() {
    return {
      isNewUser: false,
      titleMeta: '',
      scrollBottomNum: 0, //
      scrollLoadLock: false, // 滚动加载下一篇 lock
      pagetype: 'detail', // 页面类型
      newstype: '', // 新闻类别
      isclickclose: false, // 点击了关闭按钮
      nextView: false,
      loading: false,
      uk: '', // 新闻uk
      id: '', // 话题id
      animateClass: '',
      scrollTopClass: false,
      is404: false, // 是否是404
      data: {
        content: '',
        date: '',
        source: '',
        topic: '',
      },
      isUp: false, // 是否展开
      recommendNewsData: [], // 推荐话题数据
      recndata_line1: [],
      recndata_line2: [],
      recndata_line3: [],
      viewHotTopic: true, // 是否展现推荐话题栏目
    };
  },
  created() {
    const { id, uk } = this.$route.params;
    this.uk = uk;
    this.id = id;
    addClass(document.getElementById('talk-topic-cos'), 'talk-topic-fixed');
    this.checkNewUser();
  },
  mounted() {
    this.isie = isIEversion();
    this.$store.commit('SET_CURRENTTOPICID', this.id);
    this.initData();
    this.listenNextNews();
    this.initRecommendNews();
  },
  computed: {
    ...mapState([
      'isHoverTopic',
      'currentTopicId',
      'isViewDetail',
      'topicInfo',
      'currentTopicId',
      'commentNum',
      'hasUseRefer',
      'userInfo',
    ]),
  },
  beforeRouteEnter(to, from, next) {
    FormlY = from.path;
    next();
  },
  beforeRouteLeave(to, from, next) {
    // 点击浏览的后退按钮，关闭新闻页操作动画
    if (this.$refs['talk-details']) {
      this.$refs['talk-details'].style.transition = 'none';
    }
    this.scrollTopClass = false;
    this.$store.commit('setViewDetail', false);
    document.body.style.overflow = 'Visible';
    next();
  },
  methods: {
    // 检测是否是新用户
    checkNewUser() {
      const noNewUser = VueCookies.get('TT_NONEWUSER');
      if (!noNewUser) {
        this.isNewUser = true;
        VueCookies.set('TT_NONEWUSER', '1', '1y');
        setTimeout(() => {
          this.isNewUser = false;
        }, 7000);
      }
    },
    // active日志上报
    reportLog() {
      baiduLog();
      let pdata = VueCookies.get('TJ_DATA');
      VueCookies.remove('TJ_DATA');
      if (pdata) {
        pdata = pdata.split('|');
      } else {
        pdata = [];
      }
      let vfrom = '';
      if (!this.hasUseRefer && document.referrer) {
        vfrom = document.referrer;
        this.$store.commit('SET_HASUSEERFER', true);
      } else {
        if (!FormlY || FormlY === '/') vfrom = 'null';
        else vfrom = `${location.protocol}//${location.host}${FormlY}`;
      }

      activeLog({
        accid: this.userInfo ? this.userInfo.id : -1,
        btype: pdata[0] || 'null',
        subtype: pdata[1] || 'null',
        pageType: this.pagetype,
        idx: pdata[2] || 'null',
        newstype: this.newstype,
        pgnum: pdata[4] || 'null',
        from: vfrom,
        to: window.location.href,
        fr_url: vfrom,
      });
    },
    // 新闻404日志上报
    reportLog404() {
      baiduLog();
      let pdata = VueCookies.get('TJ_DATA');
      VueCookies.remove('TJ_DATA');
      if (pdata) {
        pdata = pdata.split('|');
      } else {
        pdata = [];
      }

      let vfrom = '';
      if (!this.hasUseRefer && document.referrer) {
        vfrom = document.referrer;
        this.$store.commit('SET_HASUSEERFER', true);
      } else {
        if (!FormlY || FormlY === '/') vfrom = 'null';
        else vfrom = `${location.protocol}//${location.host}${FormlY}`;
      }

      err404Log({
        accid: this.userInfo ? this.userInfo.id : -1,
        btype: pdata[0] || 'null',
        subtype: pdata[1] || 'null',
        pageType: '404',
        idx: pdata[2] || 'null',
        newstype: '404',
        pgnum: pdata[4] || 'null',
        from: vfrom,
        to: window.location.href,
        fr_url: vfrom,
      });
    },
    // 话题信息栏，被MouseOver的时候
    topicMouseOver() {
      const $headNav = document.getElementById('header-placeholder');
      if ($headNav) $headNav.style['z-index'] = 3;
      if (this.$refs['all-scroll']) {
        this.$refs['all-scroll'].style.left = '-7px';
        this.$refs['all-scroll'].style.overflowY = 'hidden';
      }
      if (!this.isViewDetail) return;
      this.$store.commit('SET_ISHOVERTOPIC', true);
    },
    // 话题信息栏，被MouseLeave的时候
    topicMouseLeave() {
      const $headNav = document.getElementById('header-placeholder');
      if ($headNav) $headNav.style['z-index'] = 11;
      if (this.$refs['all-scroll']) {
        this.$refs['all-scroll'].style.left = '0';
        this.$refs['all-scroll'].style.overflowY = 'scroll';
      }
      if (!this.isViewDetail) return;
      this.$store.commit('SET_ISHOVERTOPIC', false);
    },
    // 关闭按钮，关掉新闻详情
    closeTalk(subtype, cb, newstype) {
      removeClass(document.getElementById('xcpnav'), 'narrow-main');
      this.isclickclose = true;
      if (this.$refs['talk-details']) {
        this.$refs['talk-details'].style.transition = 'none';
      }
      this.scrollTopClass = false;
      this.$store.commit('setViewDetail', false);
      if (subtype) {
        if (this.is404) {
          VueCookies.set('TJ_DATA', `404|back|0|0`);
        } else {
          VueCookies.set('TJ_DATA', `${this.pagetype}|${subtype}|0|0`);
        }
      }
      this.$nextTick(() => {
        // 推荐话题新闻当前页跳转使用
        if (newstype) {
          this.$router.push({
            path: `/talk/${this.id}/`,
            query: { column: newstype },
          });
        } else {
          this.$router.push({ path: `/talk/${this.id}/` });
        }

        document.body.style.overflow = 'Visible';
        const $tp = document.getElementById('talk-topic');
        const $tpos = document.getElementById('talk-topic-cos');
        $tp.style.transition = 'transform 0.5s';
        removeClass($tpos, 'talk-topic-fixed');
        $tpos.style.height = 'auto';
        cb && cb();
      });
    },
    // 滚动动画
    scrollAnimation() {
      // const cnt = this.$refs['talk-details'];
      const cnt = this.$refs['detail-content'];
      const maincnt = document.getElementById('scroll-inner');
      const $relate = document.getElementById('relate');
      const $all = this.$refs['all-scroll'];
      const $talkstage = document.getElementById('talk-stage');
      const scope = this;
      if (!$all) return;
      $all.addEventListener(
        'scroll',
        function(e) {
          e.preventDefault();
          const o = getScrollPosition($all);
          let sp = o.y;
          const sb = parseInt(sp);
          if (sb < 258) {
            const top = 86 - parseInt(sb / 3);
            cnt.style.top = `${top}px`;
            maincnt.style.top = `${sb}px`;
            $relate.style.top = `${sb}px`;
            $talkstage.style.transform = `translateZ(-${parseInt(sb / 10)}px)`;
          }
          // 滚动首次展开推荐话题
          if (scope.isOnceUser && sp > 10) {
            scope.isOnceUser = false;
            scope.isUp = true;
            VueCookies.set('TT_ONCEUSER', '1');
          }
        },
        true
      );
    },
    // 初始化新闻详情数据
    initData() {
      this.animateClass = 'narrow-topic';
      this.loading = true;
      this.$store.commit('setViewDetail', true);
      getNewsData(this.uk)
        .then((response) => {
          this.loading = false;
          if (this.isclickclose) return;
          if (!response) {
            this.titleMeta = '探讨';
            this.reportLog404();
            this.is404 = true;
            this.$store.commit('setViewDetail', true);
            this.$store.commit('SET_CURRNETNEWSID', this.uk);
            document.body.style.overflow = 'hidden';
            if (!this.isie) this.scrollAnimation();
            return;
          }
          const { data } = response;
          this.titleMeta = `${data.topic}_探讨`;
          let articleContent = data.content.replace(/__page_break_flag__/g, '');
          if (!isNeedWebp) {
            articleContent = articleContent.replace(/_\.webp/g, '');
          }
          this.data.content = articleContent;
          this.data.date = data.date;
          this.data.source = data.source;

          this.newstype = data.type;
          this.reportLog();

          this.$store.commit('setViewDetail', true);
          this.$store.commit('SET_CURRNETNEWSID', this.uk);
          document.body.style.overflow = 'hidden';
          // 动画之后，再展现新闻内容，1s的小技巧
          setTimeout(() => {
            this.data.topic = data.topic;
          }, 600);
          this.nextView = true;
          this.$nextTick(() => {
            if (!this.isie) this.scrollAnimation();
            this.setCloseClass();
            const $tp = document.getElementById('talk-topic');
            $tp.style.transition = 'all 0.5s';
          });
        })
        .catch(() => {
          if (this.isclickclose) return;
          this.titleMeta = '探讨';
          this.reportLog404();
          this.loading = false;
          this.is404 = true;
          this.$store.commit('setViewDetail', true);
          this.$store.commit('SET_CURRNETNEWSID', this.uk);
          document.body.style.overflow = 'hidden';
          if (!this.isie) this.scrollAnimation();
          this.setCloseClass();
          const $tp = document.getElementById('talk-topic');
          $tp.style.transition = 'all 0.5s';
        });
    },
    // 设置关闭按钮的位置
    setCloseClass() {
      setTimeout(() => {
        this.$refs['close'].style.display = 'block';
      }, 800);
    },
    //返回首页
    backHome() {
      this.$router.push({
        path: '/',
      });
      this.$refs['all-scroll'].scrollTop = 0;
    },
    // 回到顶部
    backTop() {
      const $cnt = this.$refs['all-scroll'];
      if ($cnt) $cnt.scrollTop = 0;
    },
    // 监听下一篇新闻
    listenNextNews() {
      const scope = this;
      let mousewheelevt = /Firefox/i.test(navigator.userAgent)
        ? 'DOMMouseScroll'
        : 'mousewheel';
      // if (document.attachEvent) {
      //   document.attachEvent('on' + mousewheelevt, function(e) {
      //     scope.wheelcallback(e);
      //   });
      // } else if (document.addEventListener) {
      //   document.addEventListener(
      //     mousewheelevt,
      //     function(e) {
      //       e = e || window.event;
      //       if (e.detail) {
      //         scope.wheelcallback(e);
      //       } else if (e.wheelDelta) {
      //         scope.wheelcallback(e);
      //       }
      //     },
      //     false
      //   );
      // }

      if (document.attachEvent) {
        document.attachEvent('on' + mousewheelevt, scope.wheelcallback);
      } else if (document.addEventListener) {
        document.addEventListener(mousewheelevt, scope.wheelcallback, false);
      }
    },
    // 监听滚轮事件 e
    wheelcallback(e) {
      e = e || window.event;
      if (this.scrollLoadLock) return;
      if (e.deltaY > 0 || e.wheelDelta < 0 || e.detail > 0) {
        this.loadNextNews();
      }
    },
    // 加载下一篇
    loadNextNews() {
      // 是否达到底部
      const $next = document.getElementById('relate');
      const $scroll = document.getElementById('all-scroll');
      const screenH = getWindowHeight();
      if (!$next || !$scroll) return;
      let bottomDistance = Math.round($next.getBoundingClientRect().bottom);
      const $tombstone = document.getElementById('content-tombstones');
      // console.log('bottomDistance', bottomDistance, 'screenH', screenH);
      const distance = this.isie ? 75 : 5;
      if (
        bottomDistance + distance > screenH &&
        bottomDistance - distance < screenH
      ) {
        ++this.scrollBottomNum;
        if (this.scrollBottomNum === 1) {
          // 加载啊趋势
          $scroll.style.overflow = 'hidden';
          $tombstone.style.height = `117px`;
          scrollToDistance($scroll, 117, 800, function() {
            $scroll.removeAttribute('style');
          });
          clearTimeout(this.scrollBottomTimer);
          this.scrollBottomTimer = setTimeout(() => {
            this.scrollBottomNum = 0;
            $tombstone.style.height = '0';
          }, 2000);
        }

        if (this.scrollBottomNum === 2 && !this.scrollLoadLock) {
          this.scrollLoadLock = true;
          clearTimeout(this.scrollBottomTimer);
          setTimeout(() => {
            this.scrollBottomNum = 0;
            $tombstone.style.height = '0';
            this.closeTalk('next');
            // this.scrollLoadLock = false;
            $scroll.removeAttribute('style');
          }, 50);
        }
      }
    },
    // 展开推荐话题
    pcikDownRecTopic() {
      this.isUp = true;
    },
    // 收缩话题推荐
    packUpRecTopic() {
      this.isUp = false;
    },
    // 初始化推荐新闻
    initRecommendNews() {
      getRecommendNewsData(this.id).then((res) => {
        if (res.length >= 5) {
          this.checkUserOnce();
          this.recommendNewsData = res;
          this.recndata_line1 = res.slice(0, 3);
          this.recndata_line2 = res.slice(3, 6);
          this.recndata_line3 = res.slice(6, 9);
          if (this.isie && this.isOnceUser) {
            this.isOnceUser = false;
            this.$nextTick(() => {
              this.isUp = true;
            });
            VueCookies.set('TT_ONCEUSER', '1');
          }
        }
      });
    },
    // 点击推荐新闻，当前页跳转推荐新闻
    goTopicDetail(item, idx) {
      let Url;
      let scope = this;
      if (item.newstype == 'video') {
        item.pdata = `detail|tuijian|${idx}|0|0`;
        VueCookies.set('TJ_DATA', item.pdata);
        Url = `/talk/${this.id}/`;
        this.closeTalk(null, () => {}, 'bfq');
      } else {
        item.pdata = `detail|tuijian|${idx}|0|0`;
        VueCookies.set('TJ_DATA', item.pdata);
        Url = `/talk/${this.id}/uk/${item.uk}`;
        this.closeTalk(
          null,
          () => {
            scope.$nextTick(() => {
              setTimeout(() => {
                scope.$router.push({
                  path: Url,
                });
              }, 400);
            });
          },
          item.newstype
        );
      }
    },
    // 检测用户是否当天第一次访问
    checkUserOnce() {
      const onceUser = VueCookies.get('TT_ONCEUSER');
      if (!onceUser) {
        this.isOnceUser = true;
      }
    },
  },
  beforeDestroy() {
    let mousewheelevt = /Firefox/i.test(navigator.userAgent)
      ? 'DOMMouseScroll'
      : 'mousewheel';
    if (document.attachEvent) {
      document.detachEvent('on' + mousewheelevt, this.wheelcallback);
    } else if (document.removeEventListener) {
      document.removeEventListener(mousewheelevt, this.wheelcallback, false);
    }
  },
  components: {
    RelateTopic,
    loadIcon,
  },
  watch: {
    isViewDetail(newValue) {
      if (newValue) {
        this.animateClass = 'narrow-topic';
        this.viewHotTopic = true;
      } else {
        this.animateClass = '';
        this.viewHotTopic = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.all-scroll {
  width: 100%;
  position: fixed;
  height: calc(100vh - 60px);
  top: 60px;
  left: 0;
  overflow-y: scroll;
  overflow-x: visible;
  z-index: 5;
  *transform: scale(1);
  transform: scale(1) \0;
  .scroll-inner {
    float: right;
    // width: 1480px;
    width: 1476px; // 滚动条调整4px
    position: relative;
    .floatHome {
      position: fixed;
      bottom: 30px;
      width: 40px;
      height: 100px;
      opacity: 0.5;
      z-index: 1000;
      margin-left: 950px;
      cursor: pointer;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
      .back-home {
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../assets/image/back-top.png) no-repeat 0 0;
        &:hover {
          background-position: -40px 0;
        }
      }
      .scroll-top {
        position: absolute;
        bottom: 0;
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../assets/image/back-top.png) no-repeat 0 -40px;
        &:hover {
          background-position: -40px -40px;
        }
      }
    }
    .detail-close {
      display: none;
      position: fixed;
      transition: top 0.4s ease 0s;
      // top: 130px;
      top: 147px;
      width: 36px;
      height: 32px;
      font-size: 22px;
      margin-left: 940px;
      color: #999;
      z-index: 4;

      .close {
        position: absolute;
        top: 0;
        left: 0;
        width: 36px;
        height: 32px;
        background: url(../../assets/image/right-close.png) no-repeat center
          center;
        cursor: pointer;
        z-index: 11;
      }
      .guide {
        position: absolute;
        top: -80px;
        left: -26px;
        width: 110px;
        height: 78px;
        background: url(../../assets/image/guide.png) no-repeat;
        background-position: -17px -10px;
        z-index: 10;
        transition: all 0.5s;
        opacity: 0;
        filter: alpha(opacity=0);
        &.view {
          opacity: 1;
        }
        &.hide {
          opacity: 0;
        }
      }
    }
  }
  .relate {
    margin-top: 20px;
    float: right;
    width: 100%;
    position: relative;
  }
}
.detail-content {
  width: 940px;
  position: relative;
  top: 86px;
}
.talk-details {
  width: 940px;
  text-align: center;
  position: relative;
  background-color: #fff;
  // top: 70px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  padding: 0 40px;
  padding-bottom: 65px;
  text-align: left;
  box-sizing: border-box;
  transition: top 0.4s ease 0s;
  z-index: 3;
  min-height: calc(100vh - 120px);
  &.scroll-top {
    top: -134px;
  }
  &.change-zindex {
    z-index: 5;
  }
  h3 {
    padding-top: 25px;
    line-height: 30px;
    font-size: 24px;
    color: #212121;
  }
  .form {
    font-size: 12px;
    color: #999999;
    line-height: 30px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f3f3f3;
    position: relative;
    .source {
      margin-right: 8px;
    }
    .head {
      position: absolute;
      left: 0;
      top: 12px;
      width: 22px;
      height: 22px;
      img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
    }
  }
  .content {
    line-height: 30px;
    font-size: 16px;
    color: #212121;
    text-indent: 2rem;
    font-family: 'Microsoft Yahei', Avenir, 'Segoe UI', 'Hiragino Sans GB',
      STHeiti, 'Microsoft Sans Serif', 'WenQuanYi Micro Hei', sans-serif;
    p {
      margin-top: 20px;
      text-indent: 2rem;
      color: #000000;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .widt_ad {
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .record {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
    line-height: 45px;
    color: #888888;
    text-align: center;
    background-color: #f7f8f9;
    height: 45px;
  }
}
.next-icon {
  height: 60px;
  position: relative;
  .tips-icon {
    position: absolute;
    top: 50%;
    margin-left: -24px;
    left: 50%;
    margin-top: -15px;
    width: 48px;
    height: 48px;
    background: url(../../assets/image/next-icon.gif);
  }
}
.talk-topic {
  width: 940px;
  // background-color: #fff;
  // padding: 20px 20px 20px;
  box-sizing: border-box;
  transition: all 1s ease 0s;
  position: absolute;
  top: 2px;
  text-align: left;
  .talk-stage {
    padding: 20px 20px 10px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  &.narrow-topic {
    &:hover {
      opacity: 0.9;
      top: -28px;
      z-index: 4;
      -webkit-transform: scaleX(0.97);
      transform: scaleX(0.97);
      .discription,
      .title {
        opacity: 0.5;
      }
    }
    .discription,
    .title {
      opacity: 0.4;
    }
  }
  .opacity40 {
    opacity: 0.4;
  }
  .title {
    width: 840px;
    line-height: 30px;
    font-size: 24px;
    font-weight: bold;
    transition: opacity 1s ease 0s;
    vertical-align: top;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .discription {
    line-height: 24px;
    font-size: 14px;
    padding-top: 5px;
    color: #777777;
    transition: opacity 1s ease 0s;
    span {
      color: #212121;
      font-weight: bold;
    }
  }
  .focus-talk {
    position: absolute;
    right: 20px;
    top: 15px;
    .num {
      font-size: 14px;
      float: left;
      line-height: 30px;
      // margin-right: 15px;
      padding-left: 19px;
      position: relative;
      color: #1985ff;
      &::before {
        position: absolute;
        top: 7px;
        left: 0;
        width: 16px;
        height: 16px;
        content: '';
        background: url('../../assets/image/reply-coloured.png') no-repeat
          center center;
      }
    }
    .attention {
      float: left;
      display: block;
      width: 90px;
      height: 30px;
      border: 1px solid #1985ff;
      border-radius: 15px;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      color: #1985ff;
      cursor: pointer;
    }
  }
}
.count404 {
  width: 100%;
  padding-top: 200px;
  min-height: calc(100vh - 120px);
  text-align: center;
  color: #1985ff;
  .tips-404 {
    line-height: 36px;
    height: 36px;
    font-size: 16px;
    margin-bottom: 25px;
    span {
      padding-left: 50px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 36px;
        height: 36px;
        background: url(../../assets/image/404_icon.png) no-repeat center center;
      }
    }
  }
  .icon-404 {
    font-size: 60px;
    font-weight: bold;
  }
  .tips-text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 28px;
    margin-bottom: 20px;
  }
  .back {
    height: 40px;
    .btn {
      cursor: pointer;
      display: inline-block;
      line-height: 24px;
      height: 24px;
      padding: 8px 20px;
      background-color: #1984ff;
      text-align: center;
      color: #fff;
      border-radius: 3px;
    }
  }
}
.loading {
  width: 200px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -25px;
  position: absolute;
}

.news-topic {
  width: 60px;
  height: 180px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #f9f9f9;
  box-sizing: border-box;
  padding-left: 60px;
  border: 1px solid #f4f4f4;
  border: 1px solid #f4f4f4;
  box-shadow: 0 2px 5px 2px #e2e2e2;
  z-index: 1000;
  transition: width 0.3s ease 0s;
  .bounce-cnt {
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
  }
  .news-switch {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100px;
    background-color: #1985ff;
    font-size: 18px;
    color: #fff;
    padding: 40px 0px 40px 10px;
    font-weight: normal;
    border-radius: 0 5px 5px 0;
    writing-mode: vertical-lr;
    margin: 0 auto;
    letter-spacing: 5px;
    cursor: pointer;
    &:hover {
      background-color: #067afc;
    }
  }
  .newslist {
    overflow: hidden;
    float: left;
    padding: 32px 0 0 0;
    height: 116px;
    width: 100%;
    li {
      box-sizing: border-box;
      padding-left: 40px;
      width: 425px;
      height: 126px;
      float: left;
      border-left: 1px solid #ededed;
      &:nth-child(1) {
        border-left: none;
      }
    }
    .news-item {
      overflow: hidden;
      width: 100%;
      color: #333;
      margin-bottom: 10px;
      height: 32px;
      .ms-lf {
        float: left;
        padding: 5px 15px;
        border-radius: 16px;
        background-color: #ededed;
        line-height: 22px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: #e3eefb;
          color: #1985ff;
        }
      }
      .ms-rg {
        margin-left: 10px;
        float: left;
        font-size: 16px;
        line-height: 32px;
        text-align: left;
        cursor: pointer;
        width: 210px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:hover {
          color: #1985ff;
          text-decoration: underline;
        }
      }
    }
  }
  .pack-down {
    writing-mode: horizontal-tb;
    line-height: 100px;
    cursor: pointer;
  }
  .pack-up {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    font-size: 35px;
    line-height: 180px;
    cursor: pointer;
  }
  &.isUp {
    width: calc(100vw - 367px);
    width: calc(100vw - 375px) \9;
    .pack-down {
      display: none;
    }
    .recommend-switch {
      width: 45px;
      padding-left: 15px;
      cursor: default;
    }
    .bounce-cnt {
      padding-right: 50px;
      background-color: #f9f9f9;
      -webkit-animation-name: bounce;
      animation-name: bounce;
      -webkit-transform-origin: center bottom;
      transform-origin: center bottom;
      animation-duration: 1s;
      -webkit-animation-iteration-count: 1;
      animation-iteration-count: 1;
    }
  }
}
</style>

<style lang="scss">
.talk-details {
  .content {
    p {
      margin-top: 20px;
      text-indent: 2rem;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .widt_ad {
      text-align: center;
      line-height: 0;
      // padding-top: 10px;
      text-indent: 0;
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
</style>
